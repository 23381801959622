import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "content-main-holder"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_global_header = _resolveComponent("global-header");
  const _component_a_layout_header = _resolveComponent("a-layout-header");
  const _component_antd_side_menu = _resolveComponent("antd-side-menu");
  const _component_a_layout_sider = _resolveComponent("a-layout-sider");
  const _component_KeepAliveRouterView = _resolveComponent("KeepAliveRouterView");
  const _component_a_layout_content = _resolveComponent("a-layout-content");
  const _component_a_layout = _resolveComponent("a-layout");
  return _openBlock(), _createBlock(_component_a_layout, {
    class: "h-full"
  }, {
    default: _withCtx(() => [_createVNode(_component_a_layout_header, {
      style: {
        "padding": "0",
        "height": "64px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_global_header, {
        menus: $options.topMenus,
        activeMenusIndex: $options.activeMenusIndex
      }, null, 8, ["menus", "activeMenusIndex"])]),
      _: 1
    }), _createVNode(_component_a_layout, {
      style: {
        "background": "#f3f5fa"
      },
      class: "relative"
    }, {
      default: _withCtx(() => [$options.sideMenus.length ? (_openBlock(), _createBlock(_component_a_layout_sider, {
        key: 0,
        width: 200
      }, {
        default: _withCtx(() => [_createVNode(_component_antd_side_menu, {
          menus: $options.sideMenus
        }, null, 8, ["menus"])]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_a_layout_content, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_KeepAliveRouterView)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}