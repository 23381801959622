import BaseAutoImg from './BaseAutoImg';
import 'viewerjs/dist/viewer.css';
import { api as viewerApi } from 'v-viewer';
export default {
  data() {
    return {
      loaded: false
    };
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    fit: {
      type: String,
      default: ''
    },
    preview: {
      type: [Boolean, Object],
      default: false
    }
  },
  components: {
    BaseAutoImg
  },
  methods: {
    onLoad() {
      this.loaded = true;
    },
    doPreview(preview) {
      if (typeof preview === 'boolean') {
        viewerApi({
          options: {
            inline: false,
            button: true,
            navbar: true,
            title: true,
            toolbar: false,
            tooltip: true,
            movable: true,
            zoomable: true,
            rotatable: true,
            scalable: true,
            transition: true,
            fullscreen: true,
            keyboard: true
          },
          images: [this.src]
        });
      } else {
        viewerApi(preview);
      }
    }
  }
};