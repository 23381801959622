import type { RouteRecordRaw } from 'vue-router'

import { BasicLayout, BlankLayout, RouterView } from '@/layouts'
// 静态路由
export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    meta: {
      title: '登陆',
    },
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/Login.vue'),
  },
  {
    path: '/oaSso',
    children: [
      {
        path: 'loginStart',
        meta: {
          title: 'oa登陆',
        },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/oaSso/LoginStart.vue'),
      },
      {
        path: 'loginEnd',
        meta: {
          title: 'oa登陆',
        },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/oaSso/LoginEnd.vue'),
      },

      {
        path: 'redirect/:path*',
        meta: {
          title: 'oa重定向',
        },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/oaSso/Redirect.vue'),
      },
    ],
  },

  {
    path: '/sso',
    meta: {},
    component: () => import(/* webpackChunkName: "init" */ '@/pages/Sso.vue'),
  },
  {
    meta: {
      title: '404',
    },
    path: '/404',
    component: () => import(/* webpackChunkName: "exception" */ '@/pages/exception/404.vue'),
  },
  {
    meta: {
      title: '403',
    },
    path: '/403',
    component: () => import(/* webpackChunkName: "exception" */ '@/pages/exception/403.vue'),
  },
]
// 由 后台接口返回的menus(结合permissionMenuRoutes 或者 纯动态) + processedDefaultMenus 动态添加的路由
export const asyncRoutes = [
  {
    path: '/m',
    component: BasicLayout,
    // 动态添加
    children: [],
  },
  {
    path: '/b',
    component: BlankLayout,
    // 动态添加
    children: [],
  },
]
// 完整menu项
export const processedDefaultMenus = []
// 普通route，需要进行二次处理才能成为完整menu项
export const permissionMenuRoutes = [
  {
    path: '/m/oms/system/serverCache',
    meta: {
      title: '缓存管理',
    },
    component: () => import('@/pages/scopes/oms/system/ServerCache.vue'),
  },
  {
    path: '/m/oms/system/menuManage',
    meta: {
      title: '菜单管理',
    },
    component: () => import('@/pages/scopes/oms/system/menuManage/MenuManage.vue'),
  },
  {
    path: '/m/oms/system/deptPostUser',
    meta: {
      title: '组织岗位详情',
    },
    component: () => import('@/pages/scopes/oms/system/deptPostUser/DeptPostUser.vue'),
  },
  {
    path: '/m/oms/system/standarPostManage',
    meta: {
      title: '标准岗位授权',
    },
    component: () => import('@/pages/scopes/oms/system/standarPostManage/StandarPostManage.vue'),
  },
  {
    path: '/m/oms/system/deptPostUser',
    meta: {
      title: '组织岗位详情',
    },
    component: () => import('@/pages/scopes/oms/system/deptPostUser/DeptPostUser.vue'),
  },
  {
    path: '/m/oms/projectCenter/businessType',
    meta: {
      title: '业态管理',
    },
    component: () => import('@/pages/scopes/oms/projectCenter/BusinessType.vue'),
  },
  {
    path: '/m/oms/projectCenter/projectManage',
    meta: {
      title: '立项管理',
    },
    component: () => import('@/pages/scopes/oms/projectCenter/projectManage/ProjectManage.vue'),
    children: [
      {
        path: '/m/oms/projectCenter/projectManage/projectDetail',
        meta: {
          title: '项目编辑',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/projectCenter/projectManage/ProjectDetail.vue'),
      },
      {
        path: '/m/oms/projectCenter/projectManage/projectFullDetail',
        meta: {
          title: '项目立项',
          hidden: true,
        },
        component: () =>
          import('@/pages/scopes/oms/projectCenter/projectManage/projectFullDetail/ProjectFullDetail.vue'),
      },
    ],
  },
  {
    path: '/m/oms/projectCenter/deviceManage',
    meta: {
      title: '设备管理',
    },
    component: () => import('@/pages/scopes/oms/projectCenter/deivceManage/DeviceManage.vue'),
  },
  {
    path: '/m/oms/projectCenter/garageManage',
    meta: {
      title: '车库维护',
    },
    component: () => import('@/pages/scopes/oms/projectCenter/garageManage/GarageManage.vue'),
    children: [
      {
        path: '/m/oms/projectCenter/garageManage/SingleGarage',
        meta: {
          title: '新增车库',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/projectCenter/garageManage/SingleGarage.vue'),
      },
      {
        path: '/m/oms/projectCenter/garageManage/GarageDetail',
        meta: {
          title: '车库详情',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/projectCenter/garageManage/GarageDetail.vue'),
      },
    ],
  },

  {
    path: '/m/oms/projectCenter/buildingManage',
    meta: {
      title: '楼栋维护',
    },
    component: () => import('@/pages/scopes/oms/projectCenter/buildingManage/BuildingManage.vue'),
    children: [
      {
        path: '/m/oms/projectCenter/buildingManage/SingleBuilding',
        meta: {
          title: '新增楼栋',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/projectCenter/buildingManage/SingleBuilding.vue'),
      },
      {
        path: '/m/oms/projectCenter/buildingManage/BulidingEditor',
        meta: {
          title: '编辑楼栋',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/projectCenter/buildingManage/BulidingEditor.vue'),
      },
      {
        path: '/m/oms/projectCenter/buildingManage/BuildingDetail',
        meta: {
          title: '楼栋详情',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/projectCenter/buildingManage/BuildingDetail.vue'),
      },
    ],
  },
  {
    path: '/m/oms/projectCenter/staffManage',
    meta: {
      title: '人员维护',
    },
    component: () => import('@/pages/scopes/oms/projectCenter/staffManage/StaffManage.vue'),
    children: [
      {
        path: '/m/oms/projectCenter/staffManage/StaffDetail',
        meta: {
          title: '人员维护',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/projectCenter/staffManage/StaffDetail.vue'),
      },
    ],
  },
  {
    path: '/m/oms/projectCenter/preSaleBuilding',
    meta: {
      title: '预售楼栋维护',
    },
    component: () => import('@/pages/scopes/oms/projectCenter/preSaleBuildingManage/preSaleBuilding.vue'),
    children: [
      {
        path: '/m/oms/projectCenter/preSaleBuildingManage/preSaleDetail',
        meta: {
          title: '预售楼栋维护编辑',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/projectCenter/preSaleBuildingManage/preSaleDetail.vue'),
      },
      {
        path: '/m/oms/projectCenter/preSaleBuildingManage/preSaleView',
        meta: {
          title: '预售楼栋维护编辑',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/projectCenter/preSaleBuildingManage/preSaleView.vue'),
      },
    ],
  },
  {
    path: '/m/oms/plan/UnitDivision',
    meta: {
      title: '分部分项维护',
    },
    component: () => import('@/pages/scopes/oms/plan/UnitDivision.vue'),
  },
  {
    path: '/m/oms/plan/planTemplate',
    meta: {
      title: '工期模版管理',
    },
    component: () => import('@/pages/scopes/oms/plan/planTemplate/PlanTemplate.vue'),
    children: [
      {
        path: '/m/oms/plan/planTemplate/addTemplate',
        meta: {
          title: '新增模板',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/plan/planTemplate/AddTemplate.vue'),
      },
    ],
  },
  {
    path: '/m/oms/plan/buildingPlan',
    meta: {
      title: '楼栋工期计划',
    },
    redirect: '/m/oms/plan/buildingPlan/projectList',
    component: RouterView,
    children: [
      {
        path: '/m/oms/plan/buildingPlan/projectList',
        meta: {
          title: '工期计划',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/plan/buildingPlan/projectList/ProjectList.vue'),
      },
      {
        path: '/m/oms/plan/buildingPlan/projectPlanList',
        meta: {
          title: '调整计划',
          hidden: true,
          keepAlive: true,
        },
        component: () => import('@/pages/scopes/oms/plan/buildingPlan/projectPlanList/ProjectPlanList.vue'),
      },
      {
        path: '/m/oms/plan/buildingPlan/editPlan',
        meta: {
          title: '调整计划',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/plan/buildingPlan/EditPlan.vue'),
      },
      {
        path: '/m/oms/plan/buildingPlan/planDivisionDetail',
        meta: {
          title: '工序查看',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/plan/buildingPlan/PlanDivisionDetail.vue'),
      },
    ],
  },
  {
    path: '/m/oms/riskWarning/ruleManage/riskWarningManage',
    meta: {
      title: '预报警规则',
    },
    component: () => import('@/pages/scopes/oms/riskWarning/ruleManage/RiskWarningManage.vue'),
    children: [
      {
        path: '/m/oms/riskWarning/ruleManage/RiskWarningDetail',
        meta: {
          title: '新建规则',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/riskWarning/ruleManage/RiskWarningDetail.vue'),
      },
      {
        path: '/m/oms/riskWarning/ruleManage/RiskWarningView',
        meta: {
          title: '规则详情',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/riskWarning/ruleManage/RiskWarningView.vue'),
      },
    ],
  },
  {
    path: '/m/oms/riskWarning/RiskMessage',
    meta: {
      title: '预报警消息',
    },
    component: () => import('@/pages/scopes/oms/riskWarning/RiskMessage.vue'),
  },
  {
    path: '/m/oms/performance/deductionManage',
    meta: {
      title: '绩效扣分规则',
    },
    component: () => import('@/pages/scopes/oms/performance/deductionManage/DeductionManage.vue'),
    children: [
      {
        path: '/m/oms/performance/DeductuinDetail',
        meta: {
          title: '新建规则',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/performance/deductionManage/DeductuinDetail.vue'),
      },
      {
        path: '/m/oms/performance/DeductuinView',
        meta: {
          title: '规则详情',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/performance/deductionManage/DeductuinView.vue'),
      },
    ],
  },
  {
    path: '/m/oms/performance/awaredManage',
    meta: {
      title: '绩效加分规则（年度）',
    },
    component: () => import('@/pages/scopes/oms/performance/awaredManage/AwaredManage.vue'),
  },
  {
    path: '/m/oms/performance/examinationManage',
    meta: {
      title: '绩效考核报表',
    },
    component: () => import('@/pages/scopes/oms/performance/examinationManage/ExaminationReport.vue'),
    children: [
      {
        path: '/m/oms/performance/BuildingReportView',
        meta: {
          title: '楼栋绩效考核详情',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/performance/examinationManage/module/BuildingReportView.vue'),
      },
    ],
  },
  {
    path: '/m/oms/performance/starReductionManage',
    meta: {
      title: '摘星管理',
    },
    component: () => import('@/pages/scopes/oms/performance/starReductionManage/StarReductionManage.vue'),
    children: [
      {
        path: '/m/oms/performance/starReductionDetail',
        meta: {
          title: '摘星管理详情',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/performance/starReductionManage/StarReductionDetail.vue'),
      },
      {
        path: '/m/oms/performance/starReductionView',
        meta: {
          title: '摘星管理详情',
          hidden: true,
        },
        component: () => import('@/pages/scopes/oms/performance/starReductionManage/StarReductionView.vue'),
      },
    ],
  },
]
