import { createFromIconfontCN } from '@ant-design/icons-vue';
import { defineAsyncComponent, shallowRef } from 'vue';
const list = shallowRef({});
if (process.env.VUE_APP_ICON_ANTD === 'true') {
  const icons = require('@ant-design/icons-vue');
  const a = {};
  for (const i in icons) {
    a[i] = defineAsyncComponent(() => {
      return Promise.resolve(icons[i]);
    });
  }
  list.value = a;
}
const scriptUrl = process.env.VUE_APP_ICON_URLS?.split(',');
import { middlelineToPascal } from '@/utils/format';
export default {
  components: {
    AntdIcon: createFromIconfontCN({
      // iconfont
      scriptUrl
    })
  },
  props: {
    type: {
      require: true,
      type: String
    }
  },
  data() {
    return {
      componentList: list
    };
  },
  computed: {
    currentIcon() {
      return this.componentList[middlelineToPascal(this.type)];
    }
  }
};