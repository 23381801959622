import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1c6379f9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "base-auto-img"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0,
  class: "base-auto-img__loading"
};
const _hoisted_4 = {
  key: 1,
  class: "base-auto-img__error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: $props.src,
    onLoad: _cache[0] || (_cache[0] = (...args) => $options.onLoad && $options.onLoad(...args)),
    onError: _cache[1] || (_cache[1] = (...args) => $options.onError && $options.onError(...args)),
    class: "base-auto-img__img",
    style: _normalizeStyle({
      objectFit: $props.fit
    })
  }, null, 44, _hoisted_2), $data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_renderSlot(_ctx.$slots, "loading", {}, undefined, true)])) : _createCommentVNode("", true), $data.error ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "error", {}, undefined, true)])) : _createCommentVNode("", true)]);
}