import { mapState } from 'vuex';
import KeepAliveRouterView from '@sys/components/KeepAliveRouterView';
import { AntdSideMenu } from '@/components/sideMenu/index.js';
import GlobalHeader from '@/components/GlobalHeader';
export default {
  components: {
    KeepAliveRouterView,
    AntdSideMenu,
    GlobalHeader
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      menus: state => state.permission.menus
    }),
    activeMenusIndex() {
      let index = -1;
      for (let i = 0; i < this.menus.length; i++) {
        if (this.isMenuActive(this.menus[i])) {
          index = i;
          break;
        }
      }
      return index;
    },
    sideMenus() {
      return this.menus[this.activeMenusIndex]?.children || [];
    },
    topMenus() {
      return this.menus;
    }
  },
  methods: {
    isMenuActive(item) {
      let flag = false;
      const path = this.$route.path;
      function wlk(menu) {
        if (path === menu.path) {
          flag = true;
        } else {
          if (menu.children) {
            menu.children.map(m => {
              wlk(m);
            });
          }
        }
      }
      wlk(item);
      return flag;
    }
  }
};