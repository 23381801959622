import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  __name: 'App',
  setup(__props) {
    const locale = zhCN;
    return (_ctx, _cache) => {
      const _component_RouterView = _resolveComponent("RouterView");
      const _component_a_config_provider = _resolveComponent("a-config-provider");
      return _openBlock(), _createBlock(_component_a_config_provider, {
        locale: _unref(locale)
      }, {
        default: _withCtx(() => [_createVNode(_component_RouterView)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};