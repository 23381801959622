import { defineComponent, markRaw } from 'vue';
const createRandomChar = function (num) {
  let char = '';
  for (let i = 0; i < num; i++) {
    char += String.fromCharCode(Math.floor(Math.random() * 26) + 'a'.charCodeAt(0));
  }
  return char;
};
export default {
  data() {
    return {
      // components 保存需要keepAlive的组件
      components: markRaw({}),
      enabelTransition: process.env.VUE_APP_ENABLE_TRANSITION === 'true'
    };
  },
  computed: {
    loaded() {
      return this.$store.state.system.loaded;
    },
    keepAliveInclude() {
      const {
        keepAliveInclude,
        keepAliveRouteMap
      } = this.$store.state.system;
      // 这里的是route.path，需要转换成组件
      return keepAliveInclude.map(path => {
        return keepAliveRouteMap[path];
      });
    }
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.meta.keepAlive) {
          this.$store.dispatch('SetKeepAlive', this.$route.fullPath);
        }
      },
      immediate: true
    }
  },
  methods: {
    keepAliveComponent(Component, route) {
      const wrapperComponetName = 'keepAliveRoute-' + createRandomChar(5) + new Date().getTime();
      if (route.meta.keepAlive) {
        const {
          keepAliveRouteMap
        } = this.$store.state.system;
        let componentName = keepAliveRouteMap[route.fullPath];
        if (!componentName) {
          this.$store.commit('SET_KEEP_ALIVE_ROUTE_MAP', {
            path: route.fullPath,
            name: wrapperComponetName
          });
          this.components[wrapperComponetName] = defineComponent({
            name: wrapperComponetName,
            render: () => Component
          });
        }
        return this.components[componentName];
      }
      // vue bug temporary fix
      // keepalive 使用include时，所有组件都需要有name，否则会导致热更新异常
      return defineComponent({
        name: wrapperComponetName,
        render: () => Component
      });
    }
  }
};