import { h, render, watch } from 'vue'
import BaseModal from '../../components/BaseModal.vue'
import { isFunction } from '../../utils/is'

const createCustomModal = function (app, modalComponent, processor = (props, getInstance) => props) {
  const { $router } = app.config.globalProperties
  let unmount = () => {}
  watch($router.currentRoute, (to, from) => {
    if (to.fullPath !== from.fullPath) {
      unmount()
    }
  })
  return function (props) {
    const getInstance = () => {
      return instance
    }
    const mountNode = document.createElement('div')
    const processedProps = processor(props, getInstance)
    const vnode = h(modalComponent, processedProps)
    vnode.appContext = app._context
    render(vnode, mountNode)
    const instance = vnode.component
    unmount = () => {
      instance.props.visible = false
    }
    return instance
  }
}
const createSysModal = (app) => {
  return createCustomModal(app, BaseModal, (props, getInstance) => {
    if (typeof props.visible === undefined) {
      props.visible = true
    }
    // props.destroyOnClose = true
    const content = isFunction(props.content) ? props.content() : props.content
    const oldOk = props.onOk || (() => Promise.resolve())
    const oldCancel = props.onCancel || (() => Promise.resolve())
    props.content = content
    props.onOk = async () => {
      const instance = getInstance()
      await oldOk()
      instance.props.visible = false
    }
    props.onCancel = async () => {
      const instance = getInstance()
      await oldCancel()
      instance.props.visible = false
    }
    return props
  })
}
export { createCustomModal, createSysModal }
