import { isVNode as _isVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent } from "vue";
import "core-js/modules/es.array.push.js";
import treeUtils from '@sys/utils/treeUtils';
import { menuNavigate } from '@/service/navigate';
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
export default {
  props: {
    menus: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeKeys: [],
      openKeys: []
    };
  },
  watch: {
    $route: function () {
      this.updateMenuActive();
    },
    menus: {
      immediate: true,
      handler() {
        this.initOpenKeys();
      }
    }
  },
  mounted() {
    this.updateMenuActive();
  },
  methods: {
    initOpenKeys() {
      const defaultOpenKeys = [];
      treeUtils.forEach(this.menus, m => {
        if (m.children) {
          defaultOpenKeys.push(m.path);
        }
      });
      this.openKeys = defaultOpenKeys;
    },
    updateMenuActive() {
      const activeRoutes = treeUtils.findAllParents(this.menus, m => {
        return m.path === this.$route.path;
      });
      activeRoutes.shift();
      const routes = [];
      activeRoutes.map(route => {
        routes.push({
          meta: route.meta,
          path: route.path
        });
      });
      routes.push({
        path: this.$route.path,
        meta: this.$route.meta
      });
      this.activeKeys = routes.map(route => {
        return route.path;
      });
    },
    renderMenuItem(menu, level = 1) {
      const isExactActive = menu.path === this.activeKeys[this.activeKeys.length - 1];
      const subMenuItems = [];
      if (menu.children) {
        for (let i = 0; i < menu.children.length; i++) {
          if (!menu.children[i].meta.hidden) {
            subMenuItems.push(this.renderMenuItem(menu.children[i], level + 1));
          }
        }
      }
      if (!menu.meta.hidden) {
        return subMenuItems.length ? _createVNode(_resolveComponent("a-sub-menu"), {
          "class": {
            'ant-menu-submenu-selected': isExactActive
          },
          "key": menu.path
        }, {
          default: () => [subMenuItems],
          icon: () => {
            return this.renderIcon(menu, level);
          },
          title: () => {
            return this.renderTitle(menu, level);
          }
        }) : _createVNode(_resolveComponent("a-menu-item"), {
          "key": menu.path,
          "onClick": () => {
            menuNavigate(menu);
          }
        }, {
          default: () => [menu.meta.title],
          icon: () => {
            return this.renderIcon(menu, level);
          }
        });
      }
    },
    generateMenuTree(menu) {
      if (!menu.length) {
        return null;
      }
      const rootMenuTree = [];
      menu.map(m => {
        rootMenuTree.push(this.renderMenuItem(m));
      });
      return _createVNode(_resolveComponent("a-menu"), {
        "openKeys": this.openKeys,
        "onUpdate:openKeys": $event => this.openKeys = $event,
        "selectedKeys": this.activeKeys,
        "mode": "inline",
        "style": {
          height: '100%'
        }
      }, _isSlot(rootMenuTree) ? rootMenuTree : {
        default: () => [rootMenuTree]
      });
    },
    renderTitle(menu, _) {
      return _createVNode("span", {
        "onClick": e => {
          menuNavigate(menu);
          e.stopPropagation();
        }
      }, [menu.meta.title]);
    },
    renderIcon(menu, level) {
      const icon = menu.meta.icon;
      let com;
      if (!icon) {
        if (level === 1) {
          com = _createVNode(_resolveComponent("appstore-outlined"), null, null);
        }
      } else {
        const props = {
          type: icon
        };
        com = _createVNode(_resolveComponent("app-icon"), props, null);
      }
      return com;
    }
  },
  render() {
    const menuTree = this.generateMenuTree(this.menus);
    return _createVNode("div", {
      "class": "site-menu"
    }, [menuTree]);
  }
};