import { throttle } from 'lodash-es'
import config from '@/config'
import { message } from 'ant-design-vue'
import store from '@/store'
import { isBlankLayout } from '@/service/navigate'
import nprogress from 'nprogress'
import { getGlobalProperties } from '@/plugins'
export const getConfig = function () {
  return {
    authType: '',
  }
}
/** request相关 */

export const getBaseUrl = function () {
  return `${config.baseURL}`
}

export const getAuth = function () {
  const { accessToken, refreshToken } = store.state.user.auth
  return {
    accessToken,
    refreshToken,
  }
}
/** 错误展示/进度条/loading */

export const showError = function (axiosError) {
  // 请求取消，和错误已处理 两种状态，不需要再显示
  if (axiosError.code === 'E_HAS_RESOLVED' || axiosError.code === 'E_REQUEST_CANCEL') {
  } else {
    message.error(axiosError.message)
  }
}
let loader = null
let loaderCount = 0
export const showLoading = async function () {
  const { $loading } = getGlobalProperties()
  loaderCount++
  if (loaderCount === 1) {
    loader = $loading.show()
  }
}
export const hideLoading = function () {
  loaderCount--
  if (loaderCount === 0) {
    loader.hide()
  }
}

export const showProgress = function () {
  nprogress.start()
}
// success  true表示 请求正常并进度条结束。false 表示请求异常并进度条结束
export const hideProgress = function () {
  nprogress.done()
}
/** response相关 */

export const validResponse = function (res) {
  return res.data.success === true
}
// 根据resData展示错误信息
export const getErrorMessage = function (res) {
  return res.data ? res.data.message || res.data.msg : ''
}

/** 额外处理，一般不用 */

export const extraRequestHandle = function (req) {
  const { accessToken } = getAuth()
  const extraHeader = {
    Authorization: accessToken,
  }
  for (const i in extraHeader) {
    req.headers[i] = extraHeader[i]
  }
  return req
}
export const extraResponseHandle = function (res) {
  return res
}
export const extraErrorHandle = function (error) {
  const details = {
    message: error.message,
  }
  for (const i in error) {
    details[i] = error[i]
  }
  console.info('ERROR', details)
  return error
}
/** 刷新token和登出相关 */

export const is401 = function (error) {
  // 网关校验的code和业务系统校验的code
  return error.status === 401
}
export const doRefreshToken = function () {
  return Promise.resolve('doRefreshToken')
}
export const doLogout = throttle(
  function () {
    if (isBlankLayout()) {
      const { $popErrorPage } = getGlobalProperties()
      $popErrorPage({ message: '登陆失效' })
    } else {
      showError({ message: '登陆失效' })
      return store.dispatch('Logout')
    }
  },
  1000,
  { trailing: false }
)
