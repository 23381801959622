import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    render: {
      required: true,
      type: Function
    }
  },
  setup(props) {
    const node = props.render();
    return () => {
      return node;
    };
  }
});