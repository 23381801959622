import axiosInstance from '@/plugins/axios'
// 需要mock的，需要把地址暴露，并在mock模块引用
export const SYSTEM_API_LOGIN = '/oms-system/web/user/trustLogin/test'
export const SYSTEM_API_REFRESHTOKEN = '/ums/weblogin/refreshToken'
export const SYSTEM_API_GET_AUTH = '/oms-system/system/menu/getUserInfoMenu'

export const login = async ({ username, password }) => {
  const res = await axiosInstance.post(SYSTEM_API_LOGIN, {
    userName: username,
    password: password,
  })
  return {
    token: res.data,
    // refreshToken:''
  }
}

export const doRefreshToken = async ({ refreshToken }) => {
  const res = await axiosInstance.post(SYSTEM_API_REFRESHTOKEN, { refreshToken })
  return {
    token: res.data.token,
    refreshToken: res.data.refreshToken,
  }
}

export const getInfo = async () => {
  const res = await axiosInstance.post(SYSTEM_API_GET_AUTH)
  return {
    user: {
      nickname: res.data.nickname,
      mainPost: {
        postName: res.data.mainPost.postName,
        postCode: res.data.mainPost.postCode,
      },
      mainDept: {
        deptName: res.data.mainDept.deptName,
        deptCode: res.data.mainPost.deptCode,
      },
      allPost: res.data.allPost,
      userId: res.data.userId,
    },
    roles: [],
    permission: {
      menus: res.data.allMenu || [],
    },
  }
}
