import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_KeepAliveRouterView = _resolveComponent("KeepAliveRouterView");
  const _component_a_layout_content = _resolveComponent("a-layout-content");
  const _component_a_layout = _resolveComponent("a-layout");
  return _openBlock(), _createBlock(_component_a_layout, {
    class: "bg-white",
    style: {
      "min-height": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_a_layout_content, {
      class: "relative",
      style: {}
    }, {
      default: _withCtx(() => [_createVNode(_component_KeepAliveRouterView)]),
      _: 1
    })]),
    _: 1
  });
}